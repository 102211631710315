.MuiDropzoneArea-root {
    min-height: '150px';
    width: '80%';
}

.MuiListItem-root.Mui-disabled {
    opacity:0.7 !important;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside){
    background-color: #AC0000 !important;
}

.custom-nicollet{
    margin-bottom: 8px;
}

.custom-nicollet div div input{
    border-radius: 4px 0px 0px 4px;
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-right: none;
    padding: 26px 14px 26px 14px;
    color:rgba(0, 0, 0, 0.68);
    background-color: white;
    
}

/* #cutom-nicollet div div input:focus{
    border-top:    1px solid #AC0000;
  border-left:  1px solid #AC0000; 
  border-bottom: 1px solid #AC0000;
   
} */

.custom-nicollet button:only-of-type{
    border-radius: 0px 4px 4px 0px;
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-left: none;
    padding: 26px 14px 26px 14px;

}

/* .custom-nicollet button:only-of-type:focus{
    border-top:    1px solid #AC0000;
    border-right:  1px solid #AC0000; 
    border-bottom: 1px solid #AC0000;
} */

.custom-nicollet label:only-of-type{
   color:rgba(0, 0, 0, 0.54);
   background-color: white;
}

#reservation-date--ErrorMessage{
    color:red;
}

#date-of-visit--ErrorMessage{
    color:red;
}

/* #cutom-nicollet:hover{
    border:1px solid rgba(0, 0, 0, 0.68);
    border-radius:4px;
} */

/* #cutom-nicollet:focus-within{
    border:2px solid #AC0000;
    border-radius:4px;
} */

.DayPickerInput-OverlayWrapper{
    z-index: 2;
}

/* For Login carousel */
.slick-next::before{
    color:rgba(0, 0, 0, 0.54) ;
}

.slick-prev::before{
    color:rgba(0, 0, 0, 0.54) ;
}

/* a:focus{
    outline: gray dotted 2px;
    box-shadow: none !important;
} */

/* button:focus{
    outline: gray dotted 2px;
    box-shadow: none !important;
} */