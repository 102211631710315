/* DatePicker v8 styling */
:root {
    --rdp-cell-size: 40px;
    --rdp-accent-color: #1f1f20;
    --rdp-background-color: #f5f3f3;
    /* Switch to dark colors for dark themes */
    --rdp-accent-color-dark: #1f1f20;
    --rdp-background-color-dark: #AC0000;
    /* Outline border for focused elements */
    --rdp-outline: 2px solid var(--rdp-accent-color);
    /* Outline border for focused and selected elements */
    --rdp-outline-selected: 2px solid rgba(0, 0, 0, 0.75);
  }

.rdp-day_selected:not([aria-disabled='true']),
.rdp-day_selected:focus:not([aria-disabled='true']),
.rdp-day_selected:active:not([aria-disabled='true']),
.rdp-day_selected:hover:not([aria-disabled='true']) {
  color: white !important;
  background-color: var(--rdp-accent-color);
}

